:root {
  --grid-gap: 2px;
  --board__item--border: 8px solid #a88e64;
  --grid__item--size: 61px;
  --selection-font-size: 40px;
  --guess-font-size: 40px;
  --color-number: #b96d36;
  --grid__item--border-radius: 15px;
  --indicators--font-size: 1.3rem;
  --indicators--font-color: rgb(158, 95, 49);
  --input--box-shadow: 0 5px 5px #6e3424;
  --selected-cell-border: 4px solid red;
  --selected-cell-value-border: 4px solid green;
  --guess-quantity: 3;
  --guess-quantity-basis: 33.33333%;
}

@media screen and (max-width: 620px) {
  :root {
    --grid__item--size: 45px;
    --selection-font-size: 29px;
    --guess-font-size: 29px;
    --grid__item--border-radius: 11px;
    --board__item--border: 6px solid #a88e64;
    --indicators--font-size: 1.2rem;
    --input--box-shadow: 0 3px 3px #6e3424;
    --selected-cell-border: 3px solid red;
    --selected-cell-value-border: 3px solid green;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --grid__item--size: 32px;
    --selection-font-size: 18px;
    --guess-font-size: 18px;
    --grid__item--border-radius: 9px;
    --board__item--border: 6px solid #a88e64;
    --indicators--font-size: 1.2rem;
    --input--box-shadow: 0 3px 3px #6e3424;
    --selected-cell-border: 3px solid red;
    --selected-cell-value-border: 3px solid green;
  }
}

@media (max-height: 767px) and (orientation: landscape) {
  :root {
    --grid__item--size: 32px;
    --selection-font-size: 18px;
    --guess-font-size: 18px;
    --grid__item--border-radius: 9px;
    --board__item--border: 6px solid #a88e64;
    --indicators--font-size: 1.2rem;
    --input--box-shadow: 0 3px 3px #6e3424;
    --selected-cell-border: 3px solid red;
    --selected-cell-value-border: 3px solid green;
  }
}

body {
  background-image: url("./img/background.jpeg");
}

.game-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100svh;
  gap: 1rem;
  width: fit-content;
  margin: 0 auto;
  transition: transform 2s;
  /* transform: translateX(120vw);
  visibility: hidden; */
}

.game-container.playing {
  transform: translateX(0vw);
  visibility: visible;
}

.grid__item {
  box-sizing: border-box;
  margin: var(--grid-gap);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--grid__item--border-radius);
  font-size: var(--selection-font-size);
  background-color: rgba(0, 0, 0, 0.2);
  width: var(--grid__item--size);
  height: var(--grid__item--size);
  cursor: pointer;
  flex-wrap: wrap;
  /* temp instructions */
  flex: 0 0 auto;
}

.grid__item--full {
  background-color: var(--color-number);
  color: white;
}

.grid__item--empty-selected {
  box-shadow: inset 0px 0px 3px 3px red;
}

.grid__item--full-selected {
  box-shadow: inset 0px 0px 3px 3px green;
}

.unselected-cell {
  border: 4px solid transparent;
}

.selected-cell {
  border: var(--selected-cell-border);
  box-shadow: 0px 0px 10px 1px red;
}

.selected-cell-value {
  border: var(--selected-cell-value-border);
  box-shadow: 0px 0px 10px 1px green;
}

.input__container {
  /* display: flex;
  justify-content: space-around; */
  display: grid;
  grid-template-columns: repeat(9, var(--grid__item--size));
  grid-template-rows: var(--grid__item--size);
  gap: 7px;
}
.input__number {
  width: var(--grid__item--size);
  aspect-ratio: 1;
  border-radius: var(--grid__item--border-radius);
  background-color: var(--color-number);
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--selection-font-size);
  color: white;
  box-shadow: var(--input--box-shadow);
  cursor: pointer;
  box-sizing: border-box;
}
.input__number--guess {
  box-shadow: 0 0 0;
  color: #6e3424;
  background-color: transparent;
}

.input__number--used {
  background-color: rgba(255, 255, 255, 0.3);
  border: 3px solid #a88e64;
}

.input__number-disabled {
  background-color: transparent;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.3);
  border: none;
}

.input__number-disabled--value {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.3);
}

.input__number--hidden {
  /* opacity: 0; */
  visibility: hidden;
}

.input__guess {
  flex-basis: var(--guess-quantity-basis);
  font-size: calc(var(--guess-font-size) / var(--guess-quantity));
  text-align: center;
  margin: 0;
}

.indicators {
  /* display: flex;
  justify-content: space-around; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  font-size: var(--indicators--font-size);
  line-height: 32px;
}

.input--animation-correct {
  animation: correct 1s ease-in;
}
.input--animation-incorrect {
  /* animation: incorrect 600ms ease-in; */
  animation: incorrect 1000ms ease-in;
}

.item--animation-complete {
  animation: complete 1000ms linear;
}

@keyframes correct {
  10% {
    transform: translate(var(--xOffset), var(--yOffset));
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    transform: translate(var(--xOffset), var(--yOffset));
  }
  90% {
    transform: translate(0px, 0px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes incorrect {
  0% {
    box-shadow: 0 0 0;
  }
  10% {
    transform: translate(var(--xOffset), var(--yOffset));
  }
  50% {
    transform: translate(var(--xOffset), var(--yOffset)) rotate(60deg)
      scale(1.3);
  }

  80% {
    transform: translate(var(--xOffset), var(--yOffset));
  }
  100% {
    box-shadow: 0 0 0;
  }
}

@keyframes complete {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}

.timer {
  font-size: var(--indicators--font-size);
  color: var(--indicators--font-color);
  font-weight: bold;
}

.errors {
  font-size: var(--indicators--font-size);
  color: var(--indicators--font-color);
  font-weight: bold;
}

.input-hint {
  position: relative;
  height: 100%;
}

.input-hint-child {
  position: absolute;
  top: -30px;
  left: -1px;
  display: none;
}

.input-hint-child-fixed-size {
  left: -6px;
}

.input-hint-active {
  display: block;
}

@media screen and (max-width: 620px) {
  .input-hint-child {
    top: -21px;
    left: -2px;
  }

  .input-hint-child-fixed-size {
    left: -4px !important;
  }
}

@media screen and (max-width: 480px) {
  .input-hint-child {
    top: -18px;
    left: -3px;
  }
  .input-hint-child-fixed-size {
    left: -2px !important;
  }
}

@media (max-height: 767px) and (orientation: landscape) {
  .input-hint-child {
    top: -18px;
    left: -3px;
  }
}
