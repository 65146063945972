.game-controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: var(--indicators--font-size);
  color: var(--indicators--font-color);
  font-weight: bold;
}

.game-controls * {
  font-size: var(--indicators--font-size);
  color: var(--indicators--font-color);
  font-weight: bold;
  background-color: transparent;
  border: none;
  line-height: 23px;
}

.game-controls .info-btn {
  width: 30px;
  height: 30px;
  border: none;
  background-color: #a88e64;
  background-image: url("https://img1.freepng.es/20180729/fhg/kisspng-registered-trademark-symbol-clip-art-info-icon-5b5e436b0cba43.4658204515329042990521.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.game-controls .back-btn {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.game-controls .hint-btn {
  cursor: pointer;
}
