.instructions-container {
  background-color: #e9ce96;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 15px;
  box-shadow: 0 0 0 5px rgb(158, 95, 49);
}

.intructions-hidden {
  top: -50%;
}

.rule-container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.rule-description,
.rule-number {
  color: rgb(158, 95, 49);
}

.rule-number {
  font-size: 24px;
  font-weight: 400;
}
.rule-description {
  font-weight: bold;
}

.close-btn {
  background-color: transparent;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  font-size: 25px;
  line-height: 0px;
  font-weight: bold;
  text-align: center;
  padding: 0;
  margin: 0;
  border: none;
  color: rgb(158, 95, 49);
  cursor: pointer;
}
