/* .menu-container {
  background-image: url("https://img.as-creation.com/app/items/s/364995.jpg");

  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
 
  width: 100%;
  height: 100vh;
}

.menu-hidden {
  top: -50%;
}

.menu-controls {
  transition: height 500ms;
  width: max(300px, min(35%, 500px));
  height: fit-content;
  background-color: #e9ce96;

  border-radius: 15px;
  box-shadow: 0 0 0 5px rgb(158, 95, 49);
}

.menu-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-controls ul li {
  color: rgb(158, 95, 49);
  font-weight: bold;
  padding: 10px;
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
}

.menu-title-container {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -100px);
  align-items: center;
} */

.menu-container {
  background-color: #e9ce96;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-radius: 15px;
  box-shadow: 0 0 0 5px rgb(158 95 49);
  width: 300px;
  height: fit-content;
  overflow: hidden;
}

.menu-title {
  padding: 0;
  margin: 0;
  color: rgb(158, 95, 49);
  font-size: 32px;
}

.menu-options {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  font-size: 24px;
  justify-content: center;
  flex: 1;

  flex-basis: 100%;
  flex-shrink: 0;
  /* margin-right: 5px; */
}

.menu-option-item {
  color: rgb(158, 95, 49);
  /* padding: 10px; */
  /* font-size: 2rem; */
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.options-container {
  display: flex;
  transition: transform 600ms;
  transform: translateX(0%);
  gap: 20px;
  justify-content: center;
  color: rgb(158, 95, 49);
  font-weight: bold;
}

.options-container a {
  color: rgb(158, 95, 49);
}

.options-container.difficulties {
  transform: translateX(calc(-100% - 20px));
}
.options-container.stats {
  transform: translateX(calc(-200% - 40px));
}
.options-container.about {
  transform: translateX(calc(-300% - 60px));
}

/* .options-container.about {
  transform: translateX(calc(-400% - 80px));
} */

.level-container {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-shrink: 0;
  color: rgb(158, 95, 49);
}

.level {
  display: flex;
  flex-direction: column;
}

.level .title {
  margin: 10px;
  text-align: start;
}

.level .score p {
  margin: 5px 10px;
  text-align: start;
}

.level .score p span {
  font-weight: bold;
  font-size: 16px;
}

.level .score p span.empty {
  color: red;
}

.level .score {
  display: flex;
  flex-direction: column;
}

.options-container .about {
  text-align: center;
}
