.size {
  height: 35px;
  width: 25px;
}

@media screen and (max-width: 620px) {
  .size {
    height: 30px;
    width: 20px;
  }
}

@media screen and (max-width: 480px) {
  .size {
    height: 25px;
    width: 15px;
  }
}

@media (max-height: 767px) and (orientation: landscape) {
  .size {
    height: 25px;
    width: 15px;
  }
}
