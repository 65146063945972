.board {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: repeat(3, min-content);
}

.board__item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: var(--grid-gap);
}

.board__item:nth-of-type(n + 1):not(:nth-of-type(3n + 3)) {
  border-right: var(--board__item--border);
}

.board__item:nth-of-type(n + 4):not(:nth-of-type(n + 7)) {
  border-top: var(--board__item--border);
  border-bottom: var(--board__item--border);
}
